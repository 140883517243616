<script setup lang="ts">
import { useKeycloak } from '@baloise/vue-keycloak'
import { useUserStore } from '~/stores/user'
import { useCurrenciesStore } from '~/stores/currencies'

import Dashboard from '~/assets/icons/menu/dashboard.svg?component'
import Faststart from '~/assets/icons/menu/faststart.svg?component'
import Leaderboard from '~/assets/icons/menu/leaderboard.svg?component'
import Packages from '~/assets/icons/menu/packages.svg?component'
import Profile from '~/assets/icons/menu/profile.svg?component'
import Structure from '~/assets/icons/menu/structure.svg?component'
import Support from '~/assets/icons/menu/support.svg?component'
import Team from '~/assets/icons/menu/team.svg?component'
import Transactions from '~/assets/icons/menu/transactions.svg?component'
import Settings from '~/assets/icons/menu/profile.svg?component'
import Background from '~/assets/images/bg.webp'
import {setUser} from "@sentry/vue";
const isLoading = ref(true)

const userStore = useUserStore()
const currenciesStore = useCurrenciesStore()
const { isPending, decodedToken, isAuthenticated, keycloak } = useKeycloak()
watch(
  isPending,
  async (isPending) => {
    if (isPending) return

    if (!isAuthenticated.value) {
      const redirectUri = window.location.origin + window.location.pathname
      keycloak.login({ redirectUri })
      return
    }
    userStore.setDecodedToken(decodedToken.value)
    setUser({ email: decodedToken.value.email })
    await Promise.all([userStore.loadProfile(), currenciesStore.loadCurrencies()])
    isLoading.value = false
  },
  {
    immediate: true,
  },
)
const { t } = useI18n()
const navigation = [
  { name: t('menu.dashboard'), to: '/', icon: Dashboard },
  { name: t('menu.blog'), to: '/blog', icon: Profile },
  { name: t('menu.bonuses'), to: '/statistics', icon: Transactions },
  { name: t('menu.contest'), to: '/contest', icon: Leaderboard },
  { name: t('menu.structure'), to: '/structure', icon: Structure },
  { name: t('menu.teamCenter'), to: '/teamCenter', icon: Structure },
  { name: t('menu.products'), to: '/burning', icon: Packages },
  { name: t('menu.marketing'), to: '/marketing', icon: Team },
  { name: t('menu.traders'), to: '/traders', icon: Team },
  { name: t('menu.settings'), to: '/settings', icon: Settings },
  { name: t('menu.support'), to: '/support', icon: Support },
]

const sidebarOpen = ref(false)
</script>

<template>
  <div
    class="max-h-screen h-screen overflow-hidden h-full w-full"
    :style="{
      background: ` url(${Background}) no-repeat`,
      backgroundAttachment: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, .55) ',
      backgroundBlendMode: 'multiply',
      backgroundSize: 'cover',
    }"
  >
    <div
      v-if="isLoading"
      class="max-h-screen h-screen w-full flex items-center justify-center overflow-hidden"
    >
      <AppSpinner :size="48" />
    </div>
    <div v-else class="w-full max-h-screen">
      <TheLayoutMobileSidebar v-model="sidebarOpen" :navigation-items="navigation" class="card" />

      <TheLayoutStickyTopbar class="px-2 sm:px-10" @open-sidebar="sidebarOpen = true" />
      <div class="flex flex-row w-full px-2 sm:px-10 max-h-screen overflow-scroll">
        <div>
          <TheLayoutDesktopSidebar :navigation-items="navigation" class="card" />
        </div>

        <main class="flex w-full overflow-scroll">
          <div class="pt-4 pb-16 lg:pb-36 sm:px-6 lg:pr-9 w-full mx-auto">
            <Suspense>
              <router-view v-if="isAuthenticated" />
            </Suspense>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
