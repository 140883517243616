const __pages_import_0__ = () => import("/src/pages/[locale]/404.vue");
const __pages_import_1__ = () => import("/src/pages/[locale]/blog.vue");
const __pages_import_2__ = () => import("/src/pages/[locale]/burning.vue");
const __pages_import_3__ = () => import("/src/pages/[locale]/contest.vue");
const __pages_import_4__ = () => import("/src/pages/[locale]/fast-start.vue");
const __pages_import_5__ = () => import("/src/pages/[locale]/history.vue");
const __pages_import_6__ = () => import("/src/pages/[locale]/index.vue");
const __pages_import_7__ = () => import("/src/pages/[locale]/knowledge-base.vue");
const __pages_import_8__ = () => import("/src/pages/[locale]/marketing.vue");
const __pages_import_9__ = () => import("/src/pages/[locale]/packages.vue");
const __pages_import_10__ = () => import("/src/pages/[locale]/settings.vue");
const __pages_import_11__ = () => import("/src/pages/[locale]/statistics.vue");
const __pages_import_12__ = () => import("/src/pages/[locale]/structure.vue");
const __pages_import_13__ = () => import("/src/pages/[locale]/support.vue");
const __pages_import_14__ = () => import("/src/pages/[locale]/teamCenter.vue");
const __pages_import_15__ = () => import("/src/pages/[locale]/traders.vue");
const __pages_import_16__ = () => import("/src/pages/[locale]/register/[reflink].vue");
const __pages_import_17__ = () => import("/src/pages/[locale]/register/index.vue");

const routes = [{"name":"locale-404","path":"/:locale/404","component":__pages_import_0__,"props":true,"meta":{"layout":404}},{"name":"locale-blog","path":"/:locale/blog","component":__pages_import_1__,"props":true},{"name":"locale-burning","path":"/:locale/burning","component":__pages_import_2__,"props":true},{"name":"locale-contest","path":"/:locale/contest","component":__pages_import_3__,"props":true},{"name":"locale-fast-start","path":"/:locale/fast-start","component":__pages_import_4__,"props":true},{"name":"locale-history","path":"/:locale/history","component":__pages_import_5__,"props":true},{"name":"locale","path":"/:locale","component":__pages_import_6__,"props":true},{"name":"locale-knowledge-base","path":"/:locale/knowledge-base","component":__pages_import_7__,"props":true},{"name":"locale-marketing","path":"/:locale/marketing","component":__pages_import_8__,"props":true},{"name":"locale-packages","path":"/:locale/packages","component":__pages_import_9__,"props":true},{"name":"locale-settings","path":"/:locale/settings","component":__pages_import_10__,"props":true},{"name":"locale-statistics","path":"/:locale/statistics","component":__pages_import_11__,"props":true},{"name":"locale-structure","path":"/:locale/structure","component":__pages_import_12__,"props":true},{"name":"locale-support","path":"/:locale/support","component":__pages_import_13__,"props":true},{"name":"locale-teamCenter","path":"/:locale/teamcenter","component":__pages_import_14__,"props":true},{"name":"locale-traders","path":"/:locale/traders","component":__pages_import_15__,"props":true},{"name":"locale-register-reflink","path":"/:locale/register/:reflink","component":__pages_import_16__,"props":true,"meta":{"layout":"register"}},{"name":"locale-register","path":"/:locale/register","component":__pages_import_17__,"props":true,"meta":{"layout":"register"}}];

export default routes;