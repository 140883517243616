<script setup lang="ts">
import type { PropType } from 'vue'
import type { NavigationItem } from '~/types'

import Logo from '~/assets/logo.svg?component'

const props = defineProps({
  navigationItems: {
    type: Array as PropType<NavigationItem[]>,
    default: () => [],
  },
})

const route = useRoute()

const isActive = (item: NavigationItem) => {
  return route.path.startsWith(item.href)
}
</script>

<template>
  <div
    class="hidden lg:flex lg:w-64 lg:flex-col lg:inset-y-0 lg:min-w-66 xl:min-w-79 bg-gold/70 rounded-3xl"
  >
    <div
      class="flex-1 flex flex-col min-h-0 rounded-[0.625rem] overflow-hidden px-4 xl:px-1"
    >
      <div class="flex items-center flex-shrink-0 pl-2 pb-6 xl:pb-10">
      </div>
      <div class="flex-1 flex flex-col overflow-y-auto">
        <nav class="flex-1 py-4 space-y-1">
          <template v-for="item in props.navigationItems"               :key="item.name">
            <router-link
              v-if="item.to"
              :to="item.to"
              :class="['group menu-item']"
              :aria-current="isActive(item) ? 'page' : undefined"
            >
              <component
                :is="item.icon"
                :name="item.icon"
                :class="['mr-4.5 flex-shrink-0 h-5 w-5 menu-item__icon']"
              />
              {{ item.name }}
            </router-link>
            <a
              v-if="item.href"
              :href="item.href"
              target="_blank"
              :class="['group menu-item']"
              :aria-current="isActive(item) ? 'page' : undefined"
            >
              <Component
                :is="item.icon"
                :class="['mr-4.5 flex-shrink-0 h-5 w-5 menu-item__icon']"
              />
              {{ item.name }}
            </a>
            <span
                v-if="item.disabled"
                target="_blank"
                :class="['group menu-item opacity-50']"
                :aria-current="isActive(item) ? 'page' : undefined"
            >
                  <Component
                      :is="item.icon"
                      :class="['mr-4.5 flex-shrink-0 h-5 w-5 menu-item__icon']"
                  />
                  {{ item.name }}
                </span>
          </template>
        </nav>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.menu-item {
  @apply text-white/70 flex items-center px-4 py-2 xl:py-4 font-semibold rounded-[0.625rem] transition duration-200 hover:bg-opacity-[0.05] hover:bg-white hover:text-gold-500;
}
</style>

<style lang="postcss">
.menu-item {
  &.router-link-active {
    @apply bg-white bg-opacity-[0.05] text-gold-500;
  }
}
</style>
