import { createFetch } from '@vueuse/core'
import { getToken } from '@baloise/vue-keycloak'

export function useApiFetch<T>(...params: any[]) {
  const apiFetch = createFetch({
    baseUrl: (import.meta.env.VITE_BASE_URL as string) || 'https://api.tradelikecrazy.ai/rest-api',
    options: {
      async beforeFetch({ options }) {
        let token = null
        try {
          token = await getToken()
        } catch (e) {
          console.error(e)
        }
        if (token) {
          if (!options.headers) options.headers = {}

          // @ts-expect-error wrong types
          options.headers.Authorization = `Bearer ${token}`
        }

        return {
          options,
        }
      },
      async onFetchError(ctx) {
        console.log(JSON.stringify(ctx))
        console.error(ctx.response?.status)
        console.error(ctx?.data?.title)
        ctx.error = new Error(ctx?.data?.title)
        return ctx
      },
    },
    fetchOptions: {
      mode: 'cors',
    },
  })

  // @ts-expect-error no idea how to get rid of this error
  return apiFetch<T>(...params)
}
