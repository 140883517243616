<script setup lang="ts">
// https://github.com/vueuse/head
// you can use this to manipulate the document head in any components,
// they will be rendered correctly in the html results with vite-ssg
useHead({
  title: 'TradeLikeCrazy.ai',
  meta: [
    {
      name: 'description',
      // todo add a description
      content: 'TradeLikeCrazy.ai',
    },
  ],
})
</script>

<template>
  <Suspense>
    <router-view />
  </Suspense>
</template>
