button:
  about: About
  back: Back
  cancel: Cancel
  close: Close
  confirm: Confirm
  changeAvatar: Change avatar
  download: Download
  submit: Submit
  go: GO
  home: Home
  toggle_dark: Toggle dark mode
  toggle_langs: Change languages
  copied: Link has been copied
  addressCopied: Link has been copied
  deposit: Purchase
  logIn: Log in
  logOut: Log out
  createAccount: Create Account
  saveChanges: Save changes
  configureTotp: Set 2FA
  updatePassword: Update Password
  joinWaitlist: Start the journey!
not-found: Not found
homeStats:
  direct: Direct turnover
  directReferrals: Direct referrals
  team: Team member
  volume: Team Turnover
global:
  people: Person | People
  daysShort: DD
  hoursShort: HH
  minutesShort: MM
statistics:
  sum: Sum
  max: Max
  sumDay: Today's sum
  sumMonth: Month's sum
teamCenter:
  referralTree:
    title: Structure overview
home:
  statistics:
    title: Summary
    of: "{0} of {1}"
  promotion:
    title: Buy crypto with us
    text: Don't know how to buy a crypto yet? We have teamed up with {cashify}  to bring You best deal!
    button: Get crypto safely!
  membership:
    title: Membership status
    inactive: Inactive
    button: Purchase
    activeUntil: Active until {0}
  sales:
    title: Sales statistics
    total: "Total commission: {0} USD"
    activeReferrals: "Active Referrals: {0}"
  commission:
    title: Your wallet
    withdraw: Withdraw
    getReferral: Get your referral link
    yourSponsor: "Your sponsor is: {sponsor}"
    earnWithUs: Earn with us!
    join: To participate in our referral system You have to generate a reflink first.
    getLink: Generate reflink
    deposit: Deposit
    transfer: Transfer
  slug:
    title: Referral system
    text: To use our referral system you have to set a ref link. Please input your wished reflink below.
    button: Save reflink
    success: Referral link has been set.
  transactions:
    title: Transaction history
structure:
  directReferrals: Directs
  title: Referral tree
  search: Search person
  turnover: Turnover
  referrals: Referrals
  invested: Deposited
  activeUntil: Active until
  joined: Joined
  totalPackages: Total packages
  inactive: Inactive
  showDetails: Show details
  copyReflink: Copy reflink
table:
  headers:
    terminationStartDate: Start date
    name: Name
    action: Action
  transactions:
    id: Transaction ID
    amount: Type/Amount
    date: Date/Time
    type: Type
  referralTree:
    level: Level
    deposit: Deposit
    direct: Direct sales
    turnover: Turnover
    referrals: Referrals
  staking:
    marketCap: Market cap
popup:
  buyCrypto:
    title: Special Offer
    text: Together with {cashify} we have a special offer for You. Using our code "TheProMoon" You will get a special rate for each exchange. Their margin will go down to 2.5%. Please visit their website and book an exchange at one of their offices!
    button: Book exchange!
  buySubscription:
    title: Subscription purchase
    payTo: Please send funds to following USD TRC20 address. <br> The payment includes fee of 1% + 2$.
    text: Please input your telegram nickname/phone number below. We will send You a message with group link within 24hours.
    warning: Please send exactly the same amount as shows you payment system after you click deposit.
    button: Deposit
    telegramLabel: Telegram phone number/nickname
    subscriptionLabel: Choose subscription
    days: days
    discount: "{discount}% off"
    redirect: You will be redirected soon
  withdraw:
    title: Withdraw funds
    text: Please input tron network address on which You wish to receive a USDT transfer to.
    addressLabel: Tron address
    amountLabel: amountLabel
    button: Withdraw
    status: Status
    address: Address
    amount: Amount
    success: Withdraw has been initiated
  transfer:
    title: Funds transfer
    text:  To transfer funds to another user fill the form.<br> The fee of 1% will be charged.
    addressLabel: Tron address
    amountLabel: amountLabel
    button: Transfer
    status: Status
    address: Address
    amount: Amount
    success: Transfer has been successful.
  summerPromo:
    title: Summer promotion!
    text: Due to summer holidays we have prepared extra discounts! Monthly subscription is now 119$ and two-months is 199$
    button: Continue
contest:
  title: Official Promotion in Armenia
  OFFICE_EVENT_1:
    gifts:
      PART_PAID: "Explorer Package"
      FULL_PAID: "Achiever Package"
      FULL_PAID_BUSINESS: "Executive Package"
requirements:
  own:
    title: Own purchase
    text: ${amount}
  direct:
    title: Direct sales
    text: "{quantity}x ${amount}"
  gift:
    direct:
      title: Direct sales
      text: "${amount}"
    weakLeg:
      title: Weaker legs
      text: "${amount}"
bonuses:
  FUND_TRANSFER: Fund transfer
  TOKEN_EXCHANGE: Token exchange
  UNILEVEL_BONUS: Unilevel Bonus
  MATCHING_BONUS: Matching Bonus
  PROFIT: Profit
  PROFIT_LOCKED: Profit
  WITHDRAW: Withdraw
  WITHDRAW_FEE: Withdraw Fee
  PAYMENT: Payment
  PARTNER_TURNOVER: Unilevel Bonus
  PRODUCT_PURCHASE: Product purchase
  WALLET_TRANSFER: Token exchange
menu:
  settings: Settings
  products: Products
  bonuses: Transactions
  dashboard: Dashboard
  contest: Leadership promotion
  marketing: Marketing
  fastStart: Getting started
  support: Support
  blog: Announcements
  history: Commission history
  structure: Your structure
  teamCenter: Team center
  knowledgeBase: Knowledge base
  traders: Traders
  you: You
  logOut: Sign out
  copyReflink: Copy reflink
  shareReflink: Share reflink
fastStart:
  title: Getting started
traders:
  title: Traders
  feedTitle: Trading history
marketing:
  title: Marketing
history:
  fields:
    id: ID
    price: PRICE
    description: DESCRIPTION
    date: DATE
  type:
    SALES_COMMISSION: SALES COMMISSION
    PAYMENT: PAYMENT
    PRODUCT_PURCHASED: PRODUCT PURCHASED
    WITHDRAW: WITHDRAW
signup:
  title: Join us now!
  subtitle: You have been invited by {nickname} to the project. Fill the form below to continue.
  terms: Terms and Condtions
  alreadyRegistered: Already registered?
  privacy: Privacy Policy
  regulations: >
    I agree to TradeLikeCrazy.ai {terms} and {privacy}
  header:
    title: The first ever trading AI
    subtitle: Become part of trading revolution.
    caption: TradeLikeCrazy.ai
support:
  title: Submit ticket
  subtitle: fill form below to submit a support ticket.
  terms: Terms and Condtions
  alreadyRegistered: Already registered?
  privacy: Privacy Policy
  header:
    title: Technical Problem
    subtitle: "If you have any type of technical chanllenges and would like to get help from us please drop an email at support{'@'}tradelikecrazy.ai"
  footer:
    title: General Support
    subtitle: "If you have any type of challenges regarding products and would like to get help from us please drop an Email at support{'@'}tradelikecrazy.ai"
toasts:
  success:
    general: Success!
products:
  title: Purchase a package
  subtitle: Become an member today and start earning money.
  roi: You will earn from {from} to {to} monthly over period of 2 years.
  inputPrice: Select package price below to proceed.
  timeToEnd: Time to end
dialog:
  deposit:
    title: Deposit funds
    insufficientFunds: Insufficient funds. Please make a deposit first.
  confirmation:
    title: Confirmation
settings:
  telegram:
    title: Telegram Connection
  personal:
    title: Personal information
  authentication:
    title: Authentication
  profile:
    language: Language
forms:
  emailOrNickname:
    label: Email or nickname
  title:
    label: Title
  secondName:
    label: Second name
  nickname:
    label: Nickname
    exists: This nickname has already been used
  passwordRepeat:
    label: Repeat password
  privacyPolicy:
    label: I agree with the {policy}
    policy: Privacy Policy
  quantity:
    label: Quantity
  id:
    label: ID
  avatar:
    label: Avatar
    edited: Avatar has been changed
  balance:
    label: Balance
  bedType:
    label: Bed type
  billingAddress:
    label: Billing address
    placeholder: Enter billing address
  billingPeriod:
    label: Billing period
  billingPeriodStartDate:
    label: Billing period start date
  bio:
    label: Bio
    placeholder: This is your Bio
  card:
    expirationDate:
      label: Expiration Date
    name:
      label: Card Name
      placeholder: David Sanchez
    number:
      label: Card Number
    securityCode:
      label: Security Code
  city:
    label: City
    placeholder: City
  codeSignIn:
    label: Sign in using a single-use code emailed to you.
  company:
    label: Company
    placeholder: Enter company
  companyNumber:
    label: Company number
    placeholder: Company number
  country:
    label: Country
  deposit:
    label: Purchase
  description:
    label: Description
  documentNumber:
    label: Document number
  documentType:
    label: Document type
  dueDate:
    label: Due date
  duePeriod:
    label: Due period
  query:
    notExists: Given user does not exists.
  email:
    notExists: This email does not exists.
    exists: This email has already been used.
    invalid: This email is invalid.
    label: Email
    labelRegistration: Your email address
    required: Email address is required
  enabled:
    label: Enabled
  endDate:
    label: End date
  endsAt:
    label: Ends at
  endsIn:
    label: Estimated duration
  errors:
    dateAfterNow: The date must be in the future
    number:
      positive: Value must be greater than 0
    required: This field is required
    startDateAfterEndDate: The start date must be before the end date
  expiryDate:
    label: Expiry date
  field:
    label: Field
  firstName:
    label: First Name
    placeholder: John
  fullName:
    label: Full Name
    placeholder: John Doe
  gender:
    label: Gender
  houseNumber:
    label: House number
    placeholder: House number
  lastName:
    label: Last Name
    placeholder: Doe
  name:
    label: Name
    placeholder: Name
  nationalNumber:
    label: National Number
  oldPassword:
    label: Old password
  newPassword:
    label: New password
  newPasswordRepeat:
    label: Repeat new password
  password:
    error: Passwords don't match
    label: Password
  paymentPeriod:
    label: Payment period
  paymentPeriodStartDate:
    label: Payment period start date
  paymentType:
    label: Payment type
  penalties:
    label: Penalties
  phonePrefix:
    label: Prefix
  phoneNumber:
    errors:
      invalid: Invalid phone number
    label: Phone Number
  postalCode:
    label: Postal Code
    placeholder: Postal Code
  price:
    label: Price
  question:
    label: Question
    placeholder: Enter your question here
  remarks:
    label: Remarks
  repeat:
    label: Repeat
  response:
    label: Response
  role:
    admin:
      label: Admin
    label: Role
  startDate:
    label: Start date
  startsAt:
    label: Starts at (local timezone)
  state:
    label: State
  status:
    active:
      label: Active
    disabled:
      label: Disabled
    label: Status
    notActive:
      label: Not active
  street:
    label: Street
    placeholder: Street
  taxNumber:
    alreadyRegistered: You already have an account.
    invalid: This tax number is invalid
    label: Tax Number
    placeholder: Tax Number
  terminationDate:
    label: Termination date
    mustBeAfterToday: Termination date cannot be earlier than the next day
  timezone:
    label: Timezone
  type:
    label: Type
  value:
    label: Value
  variants:
    label: Variant
  website:
    label: Website
    placeholder: This is your website
  amount:
    label: Amount
  amountMax:
    label: Amount(max ${max})
footer:
  copyright: ©
  rights: TradeLikeCrazy.ai 2023
ownedProducts:
  title: Owned packages
package:
  20: Trial
  50: Starter
  100: Basic
  250: Pro
  500: Bronze
  1000: Silver
  2500: Gold
  5000: Platinum
  10000: Premium
  25000: Premier
