<script setup lang="ts">
import {Dialog, DialogOverlay, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {XIcon} from '@heroicons/vue/outline/index.js'
import type {PropType} from 'vue'
import Logo from '~/assets/logo.svg'

import type {NavigationItem} from '~/types'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  navigationItems: {
    type: Array as PropType<NavigationItem[]>,
    default: () => [],
  },
})

const emit = defineEmits(['update:modelValue'])

const close = () => {
  emit('update:modelValue', false)
}

const route = useRoute()

const isActive = (item: NavigationItem) => {
  return route.path.startsWith(item.href)
}
</script>

<template>
  <TransitionRoot as="template" :show="props.modelValue">
    <Dialog as="div" class="fixed inset-0 flex z-40 lg:hidden" @close="close">
      <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-black bg-opacity-95"/>
      </TransitionChild>
      <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
      >
        <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gold">
          <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
          >
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="close"
              >
                <span class="sr-only">Close sidebar</span>
                <XIcon class="h-6 w-6 text-white" aria-hidden="true"/>
              </button>
            </div>
          </TransitionChild>
          <div class="flex-shrink-0 flex items-center px-4">
            <Logo class="w-auto" />
          </div>
          <hr class="text-white opacity-[0.05] mx-4 mt-6"/>
          <div class="mt-6 flex-1 h-0 overflow-y-auto">
            <nav class="px-2 space-y-1">
              <template v-for="(item, x) in props.navigationItems"               :key="item.name">
                <router-link
                    v-if="item.to"
                    :to="item.to"
                    :class="['group menu-item']"
                    :aria-current="isActive(item) ? 'page' : undefined"
                    @click="close"
                >
                  <component
                      :is="item.icon"
                      :name="item.icon"
                      :class="['mr-4.5 flex-shrink-0 h-5 w-5 menu-item__icon']"
                  />
                  {{ item.name }}
                </router-link>
                <a
                    v-if="item.href"
                    :href="item.href"
                    target="_blank"
                    :class="['group menu-item']"
                    :aria-current="isActive(item) ? 'page' : undefined"
                >
                  <component
                      :is="item.icon"
                      :name="item.icon"
                      :class="['mr-4.5 flex-shrink-0 h-5 w-5 menu-item__icon']"
                  />
                  {{ item.name }}
                </a>
                <span
                    v-if="item.disabled"
                    target="_blank"
                    :class="['group menu-item opacity-50']"
                    :aria-current="isActive(item) ? 'page' : undefined"
                >
                  <component
                      :is="item.icon"
                      :name="item.icon"
                      :class="['mr-4.5 flex-shrink-0 h-5 w-5 menu-item__icon']"
                  />
                  {{ item.name }}
                </span>
              </template>            </nav>
          </div>
        </div>
      </TransitionChild>
      <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style lang="postcss" scoped>
.menu-item {
  @apply text-white  flex items-center px-4 py-4 font-semibold rounded-[0.625rem] transition duration-200 hover:bg-opacity-[0.05] hover:bg-white hover:text-white;
}
</style>

<style lang="postcss">
.menu-item {

&
.router-link-active {
  @apply bg-white bg-opacity-[0.05];

.menu-item__icon {

use {
  @apply fill-secondary;
}

}
}
}
</style>
