import { acceptHMRUpdate, defineStore } from 'pinia'
import { useKeycloak } from '@baloise/vue-keycloak'
import { useApiFetch } from '~/composables'
import type {
  RegisterFromWaitlistSchemaFactoryType,
  RegistrationFormSchemaFactoryType,
} from '~/schema/register'
import type { UserProfile } from '~/types/profile'
import type { SettingsPersonalInformationSchemaType } from '~/schema/settings'
import type { CloudflareFile } from '~/types'
import type { Balance } from '~/types/balances'
import type { DifferenceStats } from '~/types/stats'
import { useFetch } from "@vueuse/core";

const timeZoneCityToCountry = {} as Record<string, string>

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      decodedToken: null as any | null,
      profile: null as UserProfile | null,
      balances: null as Balance[] | null,
      location: null as {
        timezone: string
        region: string
        city: string
        country: string
      } | null,
      notifications: [],
    }
  },
  getters: {
    accountId: (state) => state.decodedToken?.accountId,
    tenantId: (state) => state.decodedToken?.tenantId,
    fullName: (state) =>
      state.profile ? `${state.profile.firstName} ${state.profile.lastName}` : '-',
    nickname: (state) => (state.profile ? state.profile.nickname : '-'),
    sponsor: (state) => (state.profile ? state.profile.sponsor ? state.profile.sponsor : '-' : '-'),
    contractorId: (state) => state.decodedToken?.contractorId,
    availableBalanceForPurchase: (state) => state?.balances?.map((a) => a.amount).reduce((a,b) => a + b, 0),
    otherTokens: (state) => state?.balances?.filter((x) => x?.currency?.tag?.includes('STAKEABLE')),
    elarTokens: (state) => state?.balances?.filter((x) => !x?.currency?.tag?.includes('STAKEABLE')),
    isContractor: () => {
      const { roles } = useKeycloak()
      return roles.value.some((v) => v.startsWith('CONTRACTOR_'))
    },
    isTenant: () => {
      const { roles } = useKeycloak()
      return roles.value.some((v) => v.startsWith('TENANT_'))
    },
    slugUrls: (state) => {
      return state.profile?.slugs?.map((x) => {
        return {
          id: x.id,
          url: `https://${window.location.host}/register/${x.text}`,
          label: x.query.toLowerCase(),
        }
      })
    },
    avatarUrl: (state) => state.profile?.avatar?.url,
  },
  actions: {
    setDecodedToken(decodedToken: any) {
      this.decodedToken = decodedToken
    },
    logout() {
      const { keycloak } = useKeycloak()
      keycloak.logout({
        redirectUri: 'https://tradelikecrazy.ai',
      })
    },
    fetchBonusStats(bonus: String) {
      return useApiFetch(`/users/${this.accountId}/balances/${bonus}/stats`).get().json()
    },
    fetchProfile() {
      return useApiFetch(`/users/${this.accountId}`).get().json()
    },
    fetchBalances() {
      return useApiFetch(`/users/${this.accountId}/balances`).get().json()
    },
    fetchBalanceStats() {
      return useApiFetch(`/users/${this.accountId}/balances/stats`).get().json()
    },
    loadLocation() {
      if (!Intl) {
        return
      }
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const tzArr = timezone.split('/')
      const region = tzArr[0]
      const city = tzArr[tzArr.length - 1]
      const country = timeZoneCityToCountry[city]
      this.location = {
        timezone,
        region,
        city,
        country,
      }
    },
    async loadProfile() {
      const { data, error } = await this.fetchProfile()
      if (error.value) throw error.value
      this.loadLocation()
      this.loadBalances()

      this.profile = data.value
    },
    async loadBalances() {
      const { data, error } = await this.fetchBalances()
      if (error.value) throw error.value

      this.balances = data.value
      return data.value as Balance[]
    },
    // TODO types
    async createDeposit(values: any) {
      const { data, error } = await useApiFetch(`/users/${this.accountId}/payments/shkeeper`)
        .post(values)
        .json()
      if (error.value) throw error.value
      return data.value
    },
    // TODO types
    async createWithdraw(values: any) {
      const { data, error } = await useApiFetch(`/users/${this.accountId}/withdraws`)
        .post({
          ...values,
          price: { magnifiedAmount: values.amount, currency: { id: 1 } },
        })
        .json()
      await this.loadBalances()
      if (error.value) throw error.value
      return data.value
    },
    async createTransfer(values: any) {
      const { data, error } = await useApiFetch(`/users/${this.accountId}/balances/transfers`)
        .post(values)
        .json()
      await this.loadBalances()
      if (error.value) throw error.value
      return data.value
    },
    async editProfile(
      profile: Partial<SettingsPersonalInformationSchemaType & { avatar: CloudflareFile }>,
      withReload = true,
    ) {
      const patchResponse = await useApiFetch(`/users/${this.accountId}`)
        .patch({ ...this.profile, ...profile })
        .json()
      if (patchResponse.error.value || !withReload) return patchResponse
      try {
        await this.loadProfile()
      } catch (error) {
        return { error: ref(error) }
      }
      return patchResponse
    },
    register(registrationData: RegistrationFormSchemaFactoryType) {
      return useApiFetch('/users').post(registrationData).json()
    },

    joinForm(registrationData: RegisterFromWaitlistSchemaFactoryType) {
      return useFetch('https://formspree.io/f/xqkvygvd', {
        headers: { Accept: 'application/json' },
      })
        .post(registrationData)
        .json()
    },
    loadDifferenceStats() {
      return useApiFetch(`/trees/${this.accountId}/unilevel/differenceStats`)
        .get()
        .json<DifferenceStats>()
    },
  },
})

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useUserStore as any, import.meta.hot))
