<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { BellIcon, MenuAlt2Icon } from '@heroicons/vue/outline'
import { useKeycloak } from '@baloise/vue-keycloak'
import AppInitialsIcon from '~/components/AppInitialsIcon.vue'
import Logo from '~/assets/logo.svg?component'
import * as Sentry from "@sentry/vue";
import {useUserStore} from "~/stores/user";
import {useToast} from "~/composables/useToast";


const emit = defineEmits(['open-sidebar'])
const logOut = function () {
  const { keycloak } = useKeycloak()
  keycloak.logout({
    redirectUri: 'https://tradelikecrazy.ai',
  })
}
const { errorToast, successToast } = useToast()

const { copy } = useClipboard()
const { share } = useShare()
const { t } = useI18n()
const copyFunc = async (txt: string) => {
  try {
    await copy(txt)
    successToast(t('button.copied'))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
  }
}
const shareFunc = async (txt: string) => {
  try {
    await share({url: txt})
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
  }
}

const shareReflink = function () {
  shareFunc(store.slugUrls[0].url)
}

const copyReflink = function () {
  copyFunc(store.slugUrls[0].url)
}
const userNavigation = [
  // { name: 'Your Profile', href: '#' },
  // { name: 'Settings', href: '#' },
  { name: t('menu.copyReflink'), href: '#', onClick: copyReflink },
  { name: t('menu.shareReflink'), href: '#', onClick: shareReflink },
  { name: t('menu.logOut'), href: '#', onClick: logOut },
]
const store = useUserStore()

function telegramLoadedCallbackFunc () {
  console.log('script is loaded')
}

function yourCallbackFunction (user) {
  // gets user as an input
  // id, first_name, last_name, username,
  // photo_url, auth_date and hash
  console.log(user)
}
</script>

<template>
  <div class="top-0  z-10 flex-shrink-0">
    <div class="flex py-3  md:py-5">
      <button
        type="button"
        class="px-4 sm:pl-6 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary lg:hidden"
        @click="() => emit('open-sidebar')"
      >
        <span class="sr-only">Open sidebar</span>
        <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
      </button>
      <div class="flex-1 pr-4 sm:pr-6 lg:pr-9 flex justify-between">
        <Logo class="w-40 xl:w-auto"/>

        <div class="flex-1 flex"></div>
        <div class="ml-4 flex items-center lg:ml-6">
          <button
            type="button"
            class="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary flex items-center"
          >
            <span class="sr-only">View notifications</span>
            <!--            <BellIcon class="h-6 w-6" aria-hidden="true" />-->
          </button>
          <AppButton secondary v-for="slug in store.slugUrls" @click="copyFunc(slug.url)"  class="!bg-[#946F1A] !border-0 !text-white py-0.5 hidden sm:block">Copy reflink</AppButton>
          <!-- Profile dropdown -->
          <Menu as="div" class="ml-3 relative">
            <div>
              <MenuButton
                class="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                <span class="sr-only">Open user menu</span>
                <AppInitialsIcon class="md:w-14 md:h-14 w-10 h-10">{{ t('menu.you') }}</AppInitialsIcon>
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-100"
              >
                <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                  <a
                    :href="item.href"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    @click="item.onClick"
                    >
                    {{ item.name }}
                  </a>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>
  </div>
</template>